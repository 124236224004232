import React from 'react';
import { PLAYER_UNITS } from '../constants/units';

const TeamScreen = ({ onBack, playerTeam }) => {
  return (
    <div className="team-screen">
      <h1>Your Team</h1>
      <div className="team-grid">
        {playerTeam.map(unitName => {
          const unit = PLAYER_UNITS[unitName];
          return (
            <div key={unitName} className="team-unit-card">
              <h3>{unitName}</h3>
              <div className="unit-stats">
                <p>Health: {unit.health}</p>
                <p>Attack: {unit.attackStrength}</p>
                <p>Range: {unit.attackRange}</p>
                <p>Speed: {unit.speed}</p>
                <p>Attack Speed: {unit.attackFrequency}s</p>
              </div>
            </div>
          );
        })}
      </div>
      <button onClick={onBack} className="back-button">Back to Menu</button>
    </div>
  );
};

export default TeamScreen;
