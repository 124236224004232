import React, { useRef } from 'react';
import { PLAYER_UNITS } from '../constants/units';

const ShopScreen = ({ onBack, currency, playerTeam, onPurchase }) => {
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 350;
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="shop-screen">
      <div className="shop-header">
        <h1>Unit Shop</h1>
        <div className="shop-info">
          <p className="currency">Currency: {currency}</p>
          <p className="team-size">Team Size: {playerTeam.length}</p>
        </div>
      </div>
      <div className="shop-container">
        <button className="scroll-button scroll-left" onClick={() => scroll('left')}>
          ◀
        </button>
        <div className="shop-items" ref={scrollContainerRef}>
          {Object.entries(PLAYER_UNITS).map(([unitName, unit]) => {
            const isOwned = playerTeam.includes(unitName);
            const canAfford = currency >= unit.cost;
            
            return (
              <div key={unitName} className="shop-item">
                <h3>{unitName}</h3>
                <div className="unit-stats">
                  <p>Cost: {unit.cost}</p>
                  <p>Health: {unit.health}</p>
                  <p>Attack: {unit.attackStrength}</p>
                  <p>Range: {unit.attackRange}</p>
                  <p>Speed: {unit.speed}</p>
                </div>
                {isOwned ? (
                  <button className="owned-button" disabled>Owned</button>
                ) : (
                  <button 
                    className={`purchase-button ${!canAfford ? 'disabled' : ''}`}
                    onClick={() => onPurchase(unitName, unit.cost)}
                    disabled={!canAfford}
                  >
                    Purchase
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <button className="scroll-button scroll-right" onClick={() => scroll('right')}>
          ▶
        </button>
      </div>
      <div className="team-display">
        <h2>Your Team</h2>
        <div className="team-units">
          {playerTeam.map(unitName => (
            <div key={unitName} className="team-unit">
              {unitName}
            </div>
          ))}
        </div>
      </div>
      <button onClick={onBack} className="back-button">Back to Menu</button>
    </div>
  );
};

export default ShopScreen;
