import React from 'react';

const SettingsScreen = ({ settings, setSettings, onBack }) => {
  return (
    <div className="settings-screen">
      <h2>Settings</h2>
      <div className="settings-options">
        <div className="setting-item">
          <label>Music</label>
          <button 
            onClick={() => setSettings(prev => ({ ...prev, musicEnabled: !prev.musicEnabled }))}
          >
            {settings.musicEnabled ? 'On' : 'Off'}
          </button>
        </div>
      </div>
      <button onClick={onBack}>Return to Menu</button>
    </div>
  );
};

export default SettingsScreen; 