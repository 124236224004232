import React, { useState, useEffect, useCallback } from 'react';
import { GAME_CONSTANTS } from '../constants/levels';
import { PLAYER_UNITS, ENEMY_UNITS } from '../constants/units';
import PauseMenu from './PauseMenu';

const BattleScreen = ({ onBattleEnd, playerTeam }) => {
  const [money, setMoney] = useState(100);
  const [playerTowerHealth, setPlayerTowerHealth] = useState(500);
  const [enemyTowerHealth, setEnemyTowerHealth] = useState(200);
  const [units, setUnits] = useState([]);
  const [battleStats, setBattleStats] = useState({
    unitsDefeated: 0,
    moneyEarned: 0,
    moneySpent: 0,
  });
  const [isPaused, setIsPaused] = useState(false);

  // Define spawnUnit first
  const spawnUnit = useCallback((side, unitType) => {
    const newUnit = {
      id: Date.now(),
      side,
      type: unitType,
      position: side === 'player' ? GAME_CONSTANTS.TOWER_DISTANCE : 0,
      health: side === 'player' ? 
        PLAYER_UNITS[unitType].health : 
        ENEMY_UNITS[unitType].health,
    };

    setUnits(prev => [...prev, newUnit]);
  }, []);

  const findTarget = useCallback((unit, allUnits) => {
    const unitStats = unit.side === 'player' ? PLAYER_UNITS[unit.type] : ENEMY_UNITS[unit.type];
    const attackRange = unitStats.attackRange;

    // Check if in range of enemy tower
    if (unit.side === 'player' && 
        unit.position <= attackRange) {
      return 'tower';
    } else if (unit.side === 'enemy' && unit.position >= GAME_CONSTANTS.TOWER_DISTANCE - attackRange) {
      return 'tower';
    }

    // Check for enemy units in range
    return allUnits.find(otherUnit => {
      if (otherUnit.side === unit.side) return false;
      const distance = Math.abs(unit.position - otherUnit.position);
      return distance <= attackRange;
    });
  }, []);

  const purchaseUnit = useCallback((unitType) => {
    // Only allow purchasing units that are in the player's team
    if (!playerTeam.includes(unitType)) {
      return;
    }
    
    const unit = PLAYER_UNITS[unitType];
    if (money >= unit.cost) {
      setMoney(prev => prev - unit.cost);
      spawnUnit('player', unitType);
      setBattleStats(prev => ({
        ...prev,
        moneySpent: prev.moneySpent + unit.cost
      }));
    }
  }, [money, spawnUnit, playerTeam]);

  // Add pause menu event handlers
  // const handlePause = useCallback(() => {
  //   setIsPaused(true);
  // }, []);

  const handleResume = useCallback(() => {
    setIsPaused(false);
  }, []);

  const handleRestart = useCallback(() => {
    setIsPaused(false);
    setMoney(100);
    setPlayerTowerHealth(500);
    setEnemyTowerHealth(200);
    setUnits([]);
    setBattleStats({
      unitsDefeated: 0,
      moneyEarned: 0,
      moneySpent: 0,
    });
  }, []);

  // Money generation
  useEffect(() => {
    if (isPaused) return;
    
    const moneyInterval = setInterval(() => {
      setMoney(prev => prev + GAME_CONSTANTS.MONEY_PER_SECOND);
      setBattleStats(prev => ({
        ...prev,
        moneyEarned: prev.moneyEarned + GAME_CONSTANTS.MONEY_PER_SECOND
      }));
    }, 1000);
    return () => clearInterval(moneyInterval);
  }, [isPaused]);

  // Enemy spawning
  useEffect(() => {
    if (isPaused) return;

    const spawnInterval = setInterval(() => {
      spawnUnit('enemy', 'Enemy01');
    }, 5000);

    return () => clearInterval(spawnInterval);
  }, [spawnUnit, isPaused]);

  // Game loop
  useEffect(() => {
    if (isPaused) return;

    const gameLoop = setInterval(() => {
      setUnits(prevUnits => {
        const deadUnits = [];
        
        const updatedUnits = prevUnits.map(unit => {
          const unitStats = unit.side === 'player' ? PLAYER_UNITS[unit.type] : ENEMY_UNITS[unit.type];
          let newUnit = { ...unit };

          // Check if unit should attack
          const target = findTarget(unit, prevUnits);
          if (target) {
            if (!unit.lastAttack || Date.now() - unit.lastAttack >= unitStats.attackFrequency * 1000) {
              // Perform attack
              if (target === 'tower') {
                if (unit.side === 'enemy') {
                  setPlayerTowerHealth(prev => Math.max(0, prev - unitStats.attackStrength));
                } else {
                  setEnemyTowerHealth(prev => Math.max(0, prev - unitStats.attackStrength));
                }
              } else {
                // Attack other unit
                const targetIndex = prevUnits.findIndex(u => u.id === target.id);
                if (targetIndex !== -1) {
                  prevUnits[targetIndex].health -= unitStats.attackStrength;
                  if (prevUnits[targetIndex].health <= 0) {
                    deadUnits.push(prevUnits[targetIndex]);
                  }
                }
              }
              newUnit.lastAttack = Date.now();
            }
          } else {
            // Move unit
            const moveDistance = (unitStats.speed / GAME_CONSTANTS.BASE_SPEED_UNIT) * 0.5;
            newUnit.position += unit.side === 'player' ? -moveDistance : moveDistance;
          }

          return newUnit;
        }).filter(unit => unit.health > 0);

        if (deadUnits.length > 0) {
          setBattleStats(prev => ({
            ...prev,
            unitsDefeated: prev.unitsDefeated + deadUnits.filter(unit => unit.side === 'enemy').length
          }));
        }

        return updatedUnits;
      });
    }, 50);

    return () => clearInterval(gameLoop);
  }, [findTarget, isPaused]);

  // Check for battle end
  useEffect(() => {
    if (enemyTowerHealth <= 0) {
      onBattleEnd({ won: true, stats: battleStats });
    } else if (playerTowerHealth <= 0) {
      onBattleEnd({ won: false, stats: battleStats });
    }
  }, [enemyTowerHealth, playerTowerHealth, battleStats, onBattleEnd]);

  // Add keyboard event listener for pause
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') {
        setIsPaused(prev => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div className="battle-screen">
      {isPaused && (
        <PauseMenu
          onResume={handleResume}
          onRestart={handleRestart}
          onQuit={onBattleEnd}
        />
      )}
      <div className="game-field">
        {/* Player Tower */}
        <div className="tower player-tower">
          <div className="health-bar">
            <div 
              className="health-fill" 
              style={{ width: `${(playerTowerHealth / 500) * 100}%` }}
            />
          </div>
        </div>

        {/* Units */}
        {units.map(unit => {
          const unitStats = unit.side === 'player' ? PLAYER_UNITS[unit.type] : ENEMY_UNITS[unit.type];
          const isAttacking = unit.lastAttack && Date.now() - unit.lastAttack < 1000;
          const animation = isAttacking && unitStats.attackAnimation ? unitStats.attackAnimation : unitStats.walkAnimation;
          const animationPath = animation ? `${process.env.PUBLIC_URL}/units/${animation}` : null;
          
          return (
            <div
              key={unit.id}
              className={`unit ${unit.side}-unit ${isAttacking ? 'attacking' : ''}`}
              style={{ 
                left: `${unit.position}%`,
                backgroundImage: animationPath ? `url(${animationPath})` : 'none',
                backgroundSize: 'contain',
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                transform: unit.side === 'enemy' ? 'scaleX(1)' : 'none'
              }}
            >
              <div className="health-bar">
                <div 
                  className="health-fill" 
                  style={{ width: `${(unit.health / unitStats.health) * 100}%` }}
                />
              </div>
            </div>
          );
        })}

        {/* Enemy Tower */}
        <div className="tower enemy-tower">
          <div className="health-bar">
            <div 
              className="health-fill" 
              style={{ width: `${(enemyTowerHealth / 200) * 100}%` }}
            />
          </div>
        </div>
      </div>

      {/* Unit Purchase Menu */}
      <div className="unit-menu">
        <span className="money-display">Money: {money}</span>
        {playerTeam.map(unitType => {
          const unit = PLAYER_UNITS[unitType];
          return (
            <button
              key={unitType}
              onClick={() => purchaseUnit(unitType)}
              disabled={money < unit.cost}
            >
              {unitType} (${unit.cost})
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default BattleScreen;