import React from 'react';

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <img src="/RadiationWarsLogo001.webp" width="600" alt="Radiation Wars Logo" />
    </div>
  );
};

export default SplashScreen; 