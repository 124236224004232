import React from 'react';

const MenuScreen = ({ onFight, onSettings, onShop, onTeam }) => {
  return (
    <div className="menu-screen">
      <img src="/RadiationWarsLogo001.webp" width="400" alt="Radiation Wars Logo" />
      <div className="menu-buttons">
        <button onClick={onFight}>Fight!!</button>
        <button onClick={onShop}>Shop</button>
        <button onClick={onTeam}>Team</button>
        <button onClick={onSettings}>Settings</button>
      </div>
    </div>
  );
};

export default MenuScreen;