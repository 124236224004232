import React, { useEffect } from 'react';

const LoseScreen = ({ onBackToHome }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onBackToHome();
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, [onBackToHome]);

  return (
    <div className="lose-screen">
      <h1>Game Over!</h1>
      <button onClick={onBackToHome}>Back to Home</button>
    </div>
  );
};

export default LoseScreen;
