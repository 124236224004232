import React from 'react';
import './PauseMenu.css';

const PauseMenu = ({ onResume, onRestart, onQuit }) => {
  return (
    <div className="pause-menu-overlay">
      <div className="pause-menu">
        <h2>Game Paused</h2>
        <div className="pause-menu-buttons">
          <button onClick={onResume}>Resume</button>
          <button onClick={onRestart}>Restart Battle</button>
          <button onClick={onQuit}>Return to Main Menu</button>
        </div>
      </div>
    </div>
  );
};

export default PauseMenu;
