export const PLAYER_UNITS = {
  Bob: {
    id: 'Bob',
    cost: 30,
    attackFrequency: 4,
    attackRange: 10,
    attackStrength: 10,
    speed: 10,
    health: 50,
    attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },
  QuadBob: {
    id: 'QuadBob',
    cost: 200,
    attackFrequency: 3,
    attackRange: 15,
    attackStrength: 20,
    speed: 8,
    health: 500,
    attackAnimation: 'QuadBobAttack.gif',
    walkAnimation: 'QuadBobWalk.gif',
  }, 
  GigaBob: {
    id: 'GigaBob',
    cost: 500,
    attackFrequency: 5,
    attackRange: 10,
    attackStrength: 100,
    speed: 8,
    health: 500,
    attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },
  Rock: {
    id: 'Rock',
    cost: 45,
    attackFrequency: 4,
    attackRange: 20,
    attackStrength: 20,
    speed: 11,
    health: 85,attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },Bolder: {
    id: 'Bolder',
    cost: 120,
    attackFrequency: 6,
    attackRange: 10,
    attackStrength: 60,
    speed: 5,
    health: 300,
    attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },
  TheRock: {
    id: 'TheRock',
    cost: 600,
    attackFrequency: 8,
    attackRange: 20,
    attackStrength: 110,
    speed: 20,
    health: 550,
    attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },MiniSword: {
    id: 'MiniSword',
    cost: 40,
    attackFrequency: 4,
    attackRange: 15,
    attackStrength: 15,
    speed: 20,
    health: 80,
    attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },Dagger: {
    id: 'Dagger',
    cost: 60,
    attackFrequency:2,
    attackRange: 10,
    attackStrength: 30,
    speed: 25,
    health: 120,
    attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },MightySword: {
    id: 'MightySword',
    cost: 200,
    attackFrequency: 4,
    attackRange: 12,
    attackStrength: 55,
    speed: 25,
    health: 120,
    attackAnimation: 'BobAttack.gif',
    walkAnimation: 'BobWalk.gif',
  },
  // ... add other player units
};

export const ENEMY_UNITS = {
  Enemy01: {
    id: 'Enemy01',
    attackFrequency: 3,
    attackRange: 10,
    attackStrength: 5,
    speed: 5,
    health: 30,
    attackAnimation: 'Enemy1Attack.gif',
    walkAnimation: 'Enemy1Walk.gif'
  },
  // ... add other enemy units
}; 