import { useState, useEffect } from 'react';
import './App.css';
import SplashScreen from './components/SplashScreen';
import MenuScreen from './components/MenuScreen';
import SettingsScreen from './components/SettingsScreen';
import BattleScreen from './components/BattleScreen';
import ShopScreen from './components/ShopScreen';
import TeamScreen from './components/TeamScreen';
import WinScreen from './components/WinScreen';
import LoseScreen from './components/LoseScreen';

function App() {
  const [gameState, setGameState] = useState('splash');
  const [settings, setSettings] = useState({
    musicEnabled: true
  });
  const [playerTeam, setPlayerTeam] = useState(['Bob']); // Start with Bob
  const [currency, setCurrency] = useState(1000); // Starting currency

  // Handle splash screen timer
  useEffect(() => {
    if (gameState === 'splash') {
      const timer = setTimeout(() => {
        setGameState('menu');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [gameState]);

  const handlePurchaseUnit = (unitName, cost) => {
    if (currency >= cost && !playerTeam.includes(unitName)) {
      setCurrency(prev => prev - cost);
      setPlayerTeam(prev => [...prev, unitName]);
      return true;
    }
    return false;
  };

  const renderScreen = () => {
    switch (gameState) {
      case 'splash':
        return <SplashScreen />;
      case 'menu':
        return <MenuScreen 
          onFight={() => setGameState('battle')} 
          onSettings={() => setGameState('settings')}
          onShop={() => setGameState('shop')}
          onTeam={() => setGameState('team')}
        />;
      case 'settings':
        return <SettingsScreen settings={settings} setSettings={setSettings} onBack={() => setGameState('menu')} />;
      case 'shop':
        return <ShopScreen 
          onBack={() => setGameState('menu')} 
          currency={currency}
          playerTeam={playerTeam}
          onPurchase={handlePurchaseUnit}
        />;
      case 'team':
        return <TeamScreen 
          onBack={() => setGameState('menu')}
          playerTeam={playerTeam}
        />;
      case 'battle':
        return <BattleScreen 
          onBattleEnd={({ won }) => {
            if (won) {
              setCurrency(prev => prev + 500); // Reward for winning
              setGameState('win');
            } else {
              setGameState('lose');
            }
          }}
          playerTeam={playerTeam}
        />;
      case 'win':
        return <WinScreen onBackToHome={() => setGameState('menu')} />;
      case 'lose':
        return <LoseScreen onBackToHome={() => setGameState('menu')} />;
      default:
        return <SplashScreen />;
    }
  };

  return (
    <div className="App">
      {renderScreen()}
    </div>
  );
}

export default App;
